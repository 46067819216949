const EMAIL_TYPE = "email_type";
const EMAIL_DOMAIN = "email_domain";

export const GA4_EMAIL_TYPES = {
  BUSINESS: "BUSINESS",
  PERSONAL: "PERSONAL"
};

export const GA4_SIGNUP_SUCCESS_FRONTEND = {
  EVENT_NAME: "dubbing_signup_success_frontend",
  EVENT_PARAMS: {
    EMAIL_TYPE,
    EMAIL_DOMAIN,
    SIGNUP_METHOD: "signup_method",
    TIMESTAMP: "signup_date",
    COMPANY_SIZE: "company_size",
    COMPANY_NAME: "company_name",
    COUNTRY: "country"
  }
};

export const GA4_SIGNUP_SUCCESS_BACKEND = {
  EVENT_NAME: "dubbing_signup_success_backend"
};

export const GA4_LOGIN_SUCCESS_FRONTEND = {
  EVENT_NAME: "dubbing_login_success_frontend",
  EVENT_PARAMS: {
    EMAIL_TYPE,
    EMAIL_DOMAIN,
    SIGNUP_METHOD: "login_method",
    TIMESTAMP: "login_date",
    COMPANY_SIZE: "company_size",
    COMPANY_NAME: "company_name",
    COUNTRY: "country"
  }
};

export const GA4_LOGIN_SUCCESS_BACKEND = {
  EVENT_NAME: "dubbing_login_success_backend"
};

export const GA4_CREATE_PROJECT_START = {
  EVENT_NAME: "dubbing_create_project_start"
};

export const GA4_DOWNLOAD_FRONTEND = {
  EVENT_NAME: "dubbing_download_frontend",
  EVENT_PARAMS: {
    COMPANY_SIZE: "company_size",
    COMPANY_NAME: "company_name",
    COUNTRY: "country"
  }
};

export const GA4_DOWNLOAD_BACKEND = {
  EVENT_NAME: "dubbing_download_backend"
};
