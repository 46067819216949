import React from "react";

const MurfSuiteIcon = (): React.ReactNode => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M0 0h8a2.5 2.5 0 0 1 2.5 2.5V8A2.5 2.5 0 0 1 8 10.5H2.5A2.5 2.5 0 0 1 0 8zM0 16a2.5 2.5 0 0 1 2.5-2.5H8a2.5 2.5 0 0 1 2.5 2.5v5.5A2.5 2.5 0 0 1 8 24H0zM13.5 2.5A2.5 2.5 0 0 1 16 0h8v8a2.5 2.5 0 0 1-2.5 2.5H16A2.5 2.5 0 0 1 13.5 8zM13.5 16a2.5 2.5 0 0 1 2.5-2.5h5.5A2.5 2.5 0 0 1 24 16v8h-8a2.5 2.5 0 0 1-2.5-2.5z"
      ></path>
    </svg>
  );
};

export default MurfSuiteIcon;
