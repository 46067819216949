import { convertArrayToMap } from "@/utils/json";

export enum LANGUAGES {
  AUTO = "auto",
  ENGLISH = "en_US",
  ENGLISH_UK = "en_UK",
  ENGLISH_AUSTRALIA = "en_AU",
  ENGLISH_INDIA = "en_IN",
  ENGLISH_SCOTLAND = "en_SCOTT",
  SPANISH = "es_ES",
  SPANISH_MEXICO = "es_MX",
  FRENCH = "fr_FR",
  GERMAN = "de_DE",
  ITALIAN = "it_IT",
  PORTUGUESE = "pt_BR",
  PORTUGUESE_PORTUGAL = "pt_PT",
  HINDI = "hi_IN",
  TAMIL = "ta_IN",
  POLISH = "pl_PL",
  JAPANESE = "ja_JP",
  KOREAN = "ko_KR",
  CHINESE_SIMPLIFIED = "zh_CN",
  CHINESE_CANTONESE = "zh_HK",
  DUTCH = "nl_NL",
  ARABIC = "ar_XA",
  BENGALI = "bn_IN",
  FINNISH = "fi_FI",
  RUSSIAN = "ru_RU",
  TURKISH = "tr_TR",
  VIETNAMESE = "vi_VN",
  DANISH = "da_DK",
  INDONESIAN = "id_ID",
  ROMANIAN = "ro_RO",
  NORWEGIAN = "nb_NO",
  UKRAINIAN = "uk_UA"
}

type LanguageData = {
  language: LANGUAGES;
  name: string;
  flag: string;
};

export const LanguageData: LanguageData[] = [
  {
    language: LANGUAGES.ENGLISH,
    name: "English (US & Canada)",
    flag: "us-canada.svg"
  },
  {
    language: LANGUAGES.ENGLISH_UK,
    name: "English (UK)",
    flag: "uk.svg"
  },
  {
    language: LANGUAGES.ENGLISH_AUSTRALIA,
    name: "English (Australia)",
    flag: "australia.svg"
  },
  {
    language: LANGUAGES.ENGLISH_INDIA,
    name: "English (India)",
    flag: "india.svg"
  },
  {
    language: LANGUAGES.ENGLISH_SCOTLAND,
    name: "English (Scotland)",
    flag: "scotland.svg"
  },
  {
    language: LANGUAGES.SPANISH,
    name: "Spanish (Spain)",
    flag: "spain.png"
  },
  {
    language: LANGUAGES.SPANISH_MEXICO,
    name: "Spanish (Mexico)",
    flag: "spain-mexico.svg"
  },
  {
    language: LANGUAGES.FRENCH,
    name: "French",
    flag: "france.svg"
  },
  {
    language: LANGUAGES.GERMAN,
    name: "German",
    flag: "germany.svg"
  },
  {
    language: LANGUAGES.ITALIAN,
    name: "Italian",
    flag: "italy.svg"
  },
  {
    language: LANGUAGES.PORTUGUESE,
    name: "Portuguese (Brazil)",
    flag: "brazil-portugal.svg"
  },
  {
    language: LANGUAGES.PORTUGUESE_PORTUGAL,
    name: "Portuguese (Portugal)",
    flag: "brazil-portugal.svg"
  },
  {
    language: LANGUAGES.HINDI,
    name: "Hindi",
    flag: "india.svg"
  },
  {
    language: LANGUAGES.TAMIL,
    name: "Tamil",
    flag: "india.svg"
  },
  {
    language: LANGUAGES.BENGALI,
    name: "Bengali",
    flag: "india.svg"
  },
  {
    language: LANGUAGES.POLISH,
    name: "Polish",
    flag: "poland.svg"
  },
  {
    language: LANGUAGES.JAPANESE,
    name: "Japanese",
    flag: "japan.svg"
  },
  {
    language: LANGUAGES.KOREAN,
    name: "Korean",
    flag: "korea.svg"
  },
  {
    language: LANGUAGES.CHINESE_SIMPLIFIED,
    name: "Mandarin (Chinese)",
    flag: "china.svg"
  },
  {
    language: LANGUAGES.CHINESE_CANTONESE,
    name: "Cantonese (Chinese)",
    flag: "china.svg"
  },
  {
    language: LANGUAGES.DUTCH,
    name: "Dutch",
    flag: "netherlands.svg"
  },
  {
    language: LANGUAGES.ARABIC,
    name: "Arabic",
    flag: "arabic.svg"
  },
  {
    language: LANGUAGES.FINNISH,
    name: "Finnish",
    flag: "finland.svg"
  },
  {
    language: LANGUAGES.RUSSIAN,
    name: "Russian",
    flag: "russia.svg"
  },
  {
    language: LANGUAGES.TURKISH,
    name: "Turkish",
    flag: "turkey.svg"
  },
  {
    language: LANGUAGES.VIETNAMESE,
    name: "Vietnamese",
    flag: "vietnam.svg"
  },
  {
    language: LANGUAGES.DANISH,
    name: "Danish",
    flag: "denmark.svg"
  },
  {
    language: LANGUAGES.INDONESIAN,
    name: "Indonesian",
    flag: "indonesia.svg"
  },
  {
    language: LANGUAGES.ROMANIAN,
    name: "Romanian",
    flag: "romania.svg"
  },
  {
    language: LANGUAGES.NORWEGIAN,
    name: "Norwegian",
    flag: "norway.svg"
  },
  {
    language: LANGUAGES.UKRAINIAN,
    name: "Ukrainian",
    flag: "ukraine.svg"
  }
];

export const LanguageDataMap = convertArrayToMap(LanguageData, "language");

export const allowedTargetLanguages = LanguageData.filter(
  (lang) =>
    ![
      LANGUAGES.TAMIL,
      LANGUAGES.PORTUGUESE_PORTUGAL,
      LANGUAGES.CHINESE_CANTONESE
    ].includes(lang.language)
);
