import MurfApiIllustration from "@/components/assets/murfSuite/MurfApiIllustration";
import MurfStudioIllustration from "@/components/assets/murfSuite/MurfStudioIllustration";
import VoiceCloningIllustration from "@/components/assets/murfSuite/VoiceCloningIllustration";
import { logoAsset } from "@/utils/links";
import { IProductCard } from "./types";

export const newWindowTimeStamp = 1000 * 60 * 60 * 24 * 30; // 30 days

const initialAddedOnDate = 1737311400000; // Jan 20, 2025;

type IProductsInfo = (
  | {
      title: string;
      products: IProductCard[];
    }
  | { type: string }
)[];

export const productsInfo: IProductsInfo = [
  {
    title: "Murf Products",
    // Create SVGs for these products so that we can change the colors according to theme
    products: [
      {
        title: "Murf API",
        titleLogo: {
          dark: logoAsset("murf-rebranded-api-light-fit.svg"),
          light: logoAsset("murf-rebranded-api-dark-fit.svg")
        },
        desc: "Deploy high quality voices for your apps, website and other services at scale",
        id: "murf_api",
        integrated: false,
        isNew: true,
        addedOn: initialAddedOnDate,
        url: "/api",
        illustration: <MurfApiIllustration />
      },
      {
        title: "Murf Studio",
        titleLogo: {
          dark: logoAsset("murf-rebranded-light.svg"),
          light: logoAsset("murf-rebranded-dark.svg")
        },
        desc: "Convert text into high quality, human-like expressive voices in various languages",
        id: "murf_studio",
        integrated: false,
        url: "/",
        illustration: <MurfStudioIllustration />
      },
      {
        title: "Voice Cloning",
        desc: "Replicates a person's voice using AI, enabling lifelike speech synthesis",
        id: "voice_cloning",
        integrated: false,
        url: "/voice-cloning",
        illustration: <VoiceCloningIllustration />,
        ctaText: "Talk to us"
      }
    ]
  },
  {
    type: "divider"
  },
  // For all 3rd party white logos, invert the logos in light mode
  {
    title: "Integrations",
    products: [
      {
        title: "Powerpoint",
        desc: "Enhance presentations by narrating slides, improving accessibility and engagement",
        image: "navicon-powerpoint.svg",
        id: "powerpoint",
        integrated: true,
        url: "/integrations/powerpoint"
      },
      {
        title: "Murf GPT",
        desc: "Generate natural-sounding voiceovers effortlessly with advanced text-to-speech technology through ChatGPT",
        image: "3rd-party-logos/brand-icon-murf-gpt.svg",
        invertLogo: true,
        id: "murfGPT",
        integrated: true,
        isNew: true,
        addedOn: initialAddedOnDate,
        url: "https://chatgpt.com/g/g-zxyxOW9Sd-murf-ai-voice-generator-text-to-speech"
      },
      {
        title: "Canva",
        desc: "Add voiceovers to designs, boosting content interactivity and storytelling",
        image: "navicon-canva.svg",
        id: "canva",
        integrated: true,
        url: "/integrations/canva"
      },
      {
        title: "Google Slides",
        desc: "Convert slide text to speech, aiding accessibility and presentation delivery",
        image: "navicon-google-slides.svg",
        id: "google_slides",
        integrated: true,
        url: "/integrations/google-slides"
      },
      {
        title: "Adobe Captivate",
        desc: "Enable voice narration for eLearning, enhancing course engagement",
        image: "3rd-party-logos/navicon-adobe-captivate.svg",
        integrated: true,
        url: "/integrations/adobe-captivate",
        id: "adobe_captivate"
      },
      {
        title: "Adobe CaptivateClassic",
        desc: "Integrate speech to streamline content delivery and improve learner retention",
        image: "navicon-adobe-captivate.svg",
        id: "adobe_captivate_classic",
        integrated: true,
        url: "/integrations/adobe-captivate-classic"
      },
      {
        title: "Adobe Audition",
        desc: "Assist in audio editing by converting text scripts to voice for seamless production",
        image: "navicon-adobe-audition.svg",
        id: "adobe_audition",
        integrated: true,
        url: "/integrations/adobe-audition"
      }
    ]
  },
  {
    type: "divider"
  },
  {
    title: "Embed code in these platforms",
    products: [
      {
        title: "Articulate 360",
        desc: "Improve eLearning by integration narration, enriching content delivery",
        image: "3rd-party-logos/brand-icon-articulate360.svg",
        id: "articulate_360",
        integrated: true,
        url: "/integrations/articulate-360"
      },
      {
        title: "Webflow",
        desc: "Add audio narration, improving user experience and accessibility.",
        image: "3rd-party-logos/brand-icon-webflow.svg",
        id: "webflow",
        integrated: true,
        url: "/integrations/webflow"
      },
      {
        title: "Wix",
        desc: "Enhance websites with voiceovers, improving engagement and user accessibility",
        image: "3rd-party-logos/brand-icon-wix.svg",
        invertLogo: true,
        id: "wix",
        integrated: true,
        url: "/integrations/wix"
      },
      {
        title: "WordPress",
        desc: "Convert content to speech, improving accessibility and engagement",
        image: "3rd-party-logos/brand-icon-wordpress.svg",
        invertLogo: true,
        id: "wordpress",
        integrated: true,
        url: "/integrations/wordpress"
      },
      {
        title: "Notion",
        desc: "Add voice narration to notes, boosting content consumption",
        image: "3rd-party-logos/brand-icon-notion.svg",
        invertLogo: true,
        id: "notion",
        integrated: true,
        url: "/integrations/notion"
      },
      {
        title: "Squarespace",
        desc: "Enhance websites with speech integration, improving visitor engagement",
        image: "3rd-party-logos/brand-icon-squarespace.svg",
        invertLogo: true,
        id: "squarespace",
        integrated: true,
        url: "/integrations/squarespace"
      }
    ]
  }
];
