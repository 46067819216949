import { trackMixpanelEvent } from "./mixpanel";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { captureException } from "@sentry/react";
import ReactGA from "react-ga4";

const trackingIdGA4 = "G-2H3YQRTV6T";

export const initializeReactGa4 = () => {
  // window.rga = ReactGA;
  ReactGA.initialize(trackingIdGA4, {
    gtagOptions: {
      debug_mode: true
    }
  });
};

export const trackGA4Event = (
  event: string,
  properties = {},
  non_interaction = false
) => {
  if (!window?.gtag) {
    captureException(new Error("Google analytics not initialized."));
    trackMixpanelEvent(MIXPANEL_EVENTS.GA4_NOT_INITIALIZED);
  }

  ReactGA.event(event, {
    action: event,
    label: event,
    category: "DUB",
    nonInteraction: non_interaction,
    ...properties
  });

  // libGA.eventGA4(
  //   event,
  //   {
  //     ...properties,
  //     product_type: "DUB"
  //   },
  //   non_interaction
  // );
};

/**
 * @returns {Promise<{clientId: string, sessionId: string}>}
 */
const getGaData = () => {
  return new Promise((resolve, reject) => {
    if (!window?.gtag) {
      reject("Google analytics not initialized.");
      return;
    }
    window.gtag("get", trackingIdGA4, "client_id", (id: string) => {
      const clientId = id;
      window.gtag("get", trackingIdGA4, "session_id", (id: string) => {
        const sessionId = id;
        resolve({ clientId, sessionId });
      });
    });
  });
};

export const getGa4Data = () => {
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error("GA4 data not found after 3 seconds"));
    }, 4000);
  });
  const ga4DataPromise = getGaData();

  return Promise.race([timeoutPromise, ga4DataPromise])
    .then((data) => data)
    .catch((error) => {
      trackMixpanelEvent(MIXPANEL_EVENTS.GA4_DATA_NOT_FOUND, {
        message: error
      });

      return null;
    });
};
