import { store } from "@/config/configureAppStore";
import { LanguageDataMap } from "@/constants/languages";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { PLANS_CATEGORY } from "@/features/pricing/types";
import {
  UserSliceState,
  WorkspaceData
} from "@/reducers/slices/userSlice/userSlice";
import { AuthUser } from "@/types/firebase";
import { WORKSPACE_ROLES } from "@/types/workspace";
import mixpanel from "mixpanel-browser";

export function initializeMixpanel() {
  if (!mixpanel.__loaded) {
    mixpanel.init(import.meta.env.VITE_MIXPANEL, {
      debug: import.meta.env.VITE_MURF_ENVIRONMENT === "DEV"
    });
  }
}

export const trackMixpanelEvent = (
  event: MIXPANEL_EVENTS,
  properties?: any,
  callback?: () => any
) => {
  console.debug("MIXPANEL EVENT", event);
  if (mixpanel.__loaded && event) {
    const planDetails = store?.getState()?.workspace.workspacePlan.planDetails;
    const planData = planDetails
      ? {
          "Plan Type":
            planDetails?.category === PLANS_CATEGORY.FREE ? "Free" : "Paid",
          Plan: planDetails?.category
        }
      : {};

    mixpanel.track(
      event,
      {
        ...properties,
        ...planData,
        "Product Type": "Dub"
      },
      undefined,
      callback
    );
  }
};

export const identifyMixPanelUser = (
  authUser: AuthUser,
  userData: UserSliceState
) => {
  if (mixpanel.__loaded && authUser?.uid) {
    mixpanel.identify(authUser?.uid);
    mixpanel.people.set({
      Name: userData?.displayName || undefined,
      Email: authUser?.email,
      "Email Type": authUser.providerData[0].providerId,
      "Murf ID": authUser?.uid || undefined,
      "Sign Up Date": authUser.metadata?.createdAt,
      "Workspace IDs": userData.linkedWorkspaces.map(
        (workspace) => workspace.workspaceId
      )
    });
  }
};

export const getMixpanelLanguageName = (locale: string) => {
  if (!locale) {
    return "";
  }
  return `${LanguageDataMap[locale].name} (${locale})`;
};

export const setWorkspaceProps = (workspace: WorkspaceData) => {
  const countOfAdmins = Object.values(workspace.users).filter(
    (user) => user.role.role === WORKSPACE_ROLES.ADMIN
  )?.length;

  const countOfOwners = Object.values(workspace.users).filter(
    (user) => user.role.role === WORKSPACE_ROLES.OWNER
  )?.length;

  mixpanel.add_group("workspace_id", workspace.workspaceId);
  mixpanel.get_group("workspace_id", workspace.workspaceId).set({
    "Workspace Name": workspace.displayName,
    "No of users": Object.keys(workspace.users)?.length ?? 0,
    "Lip Sync": workspace.lipSyncEnabled,
    "Quality Check": workspace.qcEnabled,
    "Credits in Plan": workspace.assignedCredits - workspace.usedCredits,
    "Credits Left": 0,
    "No. of Owners": countOfOwners ?? 0,
    "No. of Admins": countOfAdmins ?? 0
  });
};
