import { TMember, TUser } from "@/features/user";
import { JSON_TYPE, TEXT_TYPE, api } from "@/lib/axios";
import { APIResponse } from "@/types/apiResponse";
import { IWorkspace } from "@/types/workspace";

export interface UserStateResponse extends TMember {
  activeWorkspaceId: string;
  linkedWorkspaces: IWorkspace[];
  startFlowCompleted: boolean;
  users?: [TUser];
}

interface UpdateUserNamePayload {
  firstName: string;
  lastName: string;
  displayName?: string;
}

interface RecordSignupEnrichmentPayload {
  email: string;
  userId: string;
  clientId: string;
  sessionId: string;
  emailType: string;
  signupMethod: string;
}

interface SendGaEventPayload {
  eventName: string;
  emailId: string;
  userId: string;
  clientId: string;
  sessionId: string;
  enrichData: boolean;
  additionalProperties?: Record<string, string>;
}

export interface OrganizationData {
  employeeSize: number;
  companyName: string;
  emailType: string;
  country: string;
}

export const userStateApi = {
  get: () => api.get<APIResponse<UserStateResponse>>(`/user/state`),
  fetchWorkspaceState: () => api.get<APIResponse>("/workspace/state"),
  updateUserName: (payload: UpdateUserNamePayload) =>
    api.post<APIResponse<TUser>>("/user/update", payload),
  completeStartFlow: () => api.post<APIResponse>("/user/complete-start-flow "),
  recordSignupEnrichment: ({
    email,
    userId,
    clientId,
    sessionId,
    emailType,
    signupMethod
  }: RecordSignupEnrichmentPayload) =>
    api.post<APIResponse>(
      "/user/signup-success",
      {
        email,
        userId,
        clientId,
        sessionId,
        emailType,
        signupMethod,
        timeStamp: new Date(),
        productType: "DUB"
      },
      {
        baseURL: import.meta.env.VITE_MURF_STUDIO
      }
    ),
  sendGaEvent: ({
    eventName,
    clientId,
    emailId,
    sessionId,
    userId,
    enrichData,
    additionalProperties
  }: SendGaEventPayload) =>
    api.post("/user/send-ga-event", {
      eventName,
      clientId,
      emailId,
      sessionId,
      userId,
      enrichData,
      additionalProperties,
      productType: "DUB"
    }),
  getOrganizationDetails: () =>
    api.get<APIResponse<OrganizationData>>("/user/organisation-details"),
  getVisitorRegion: () =>
    api.get(`ping/geography`, {
      headers: {
        "Content-Type": TEXT_TYPE
      },
      baseURL: import.meta.env.VITE_MURF_STUDIO
    }),
  pingCookieStatus: (action: string) =>
    api.post(`ping/cookie-action/${action}`, null, {
      headers: {
        "Content-Type": JSON_TYPE
      },
      baseURL: import.meta.env.VITE_MURF_STUDIO
    }),
  cancelTask: (taskId: string) => api.post(`/user/cancel-task?taskId=${taskId}`)
};
