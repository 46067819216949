import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import React from "react";
import { productsInfo } from "./constants";
import ProductCard from "./components/ProductCard";
import { useAppDispatch } from "@/config/configureAppStore";
import { setMurfSuiteOpenState } from "@/reducers/slices/dialogSlice/dialogSlice";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import DialogHeader from "@/components/elements/Dialog/DialogHeader";
import MurfSuiteIcon from "@/components/assets/murfSuite/MurfSuiteIcon";

const MurfSuiteDialog = (): React.ReactNode => {
  const dispatch = useAppDispatch();

  const closeMurfSuite = () => {
    dispatch(setMurfSuiteOpenState(false));
    trackMixpanelEvent(MIXPANEL_EVENTS.MURF_SUITE_DISMISSED, {});
  };

  return (
    <Dialog open={true} onClose={closeMurfSuite} fullWidth maxWidth="lg">
      <DialogHeader
        title="Murf Products and Integrations"
        icon={<MurfSuiteIcon />}
        onClose={closeMurfSuite}
      ></DialogHeader>
      <DialogContent
        sx={{
          borderTop: `1px solid`,
          borderColor: "divider",
          p: 3,
          paddingTop: (theme) => `${theme.spacing(3)} !important`
        }}
      >
        {productsInfo.map((section, index) =>
          "type" in section && section.type === "divider" ? (
            <Divider key={index} sx={{ my: 4 }} />
          ) : "title" in section && "products" in section ? (
            <Stack key={section.title} gap={2}>
              <Typography variant="subtitle1">{section.title}</Typography>
              <Grid container spacing={3}>
                {section.products?.map((p) => (
                  <Grid item xs={6} key={p.id}>
                    <ProductCard {...p} section={section.title} />
                  </Grid>
                ))}
              </Grid>
            </Stack>
          ) : null
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MurfSuiteDialog;
