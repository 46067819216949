import React from "react";

const MurfStudioIllustration = (): React.ReactNode => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <g clipPath="url(#clip0_4354_7352)">
        <g clipPath="url(#clip1_4354_7352)">
          <g filter="url(#filter0_f_4354_7352)" opacity="0.6">
            <path
              fill="#A000BA"
              d="M62.5 41.667c0 11.505-9.327 20.833-20.833 20.833s-20.834-9.327-20.834-20.833 9.328-20.834 20.834-20.834S62.5 30.161 62.5 41.667"
            ></path>
          </g>
          <g filter="url(#filter1_f_4354_7352)" opacity="0.6">
            <path
              fill="url(#paint0_linear_4354_7352)"
              d="M79.167 58.333c0 11.506-9.328 20.834-20.834 20.834S37.5 69.839 37.5 58.333 46.827 37.5 58.333 37.5s20.834 9.327 20.834 20.833"
            ></path>
          </g>
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M76.414 20.711c0-.719.583-1.302 1.302-1.302h6.324a7.6 7.6 0 0 1 7.626 7.626v44.828a7.6 7.6 0 0 1-7.626 7.626h-6.324a1.302 1.302 0 0 1 0-2.604h6.324a4.995 4.995 0 0 0 5.022-5.022V27.035a4.996 4.996 0 0 0-5.022-5.022h-6.324c-.72 0-1.302-.583-1.302-1.302M45.455 22.432a4.996 4.996 0 0 0-5.022 5.021 1.302 1.302 0 0 1-2.604 0 7.6 7.6 0 0 1 7.626-7.625h6.324a1.302 1.302 0 1 1 0 2.604zM39.13 70.98c.72 0 1.303.582 1.303 1.302a4.995 4.995 0 0 0 5.022 5.021h6.324a1.302 1.302 0 0 1 0 2.605h-6.324a7.6 7.6 0 0 1-7.626-7.626c0-.72.583-1.302 1.302-1.302M37.828 32.372c0-.72.583-1.302 1.302-1.302h4.356c3.108 0 5.518 2.41 5.518 5.518v26.419c0 3.108-2.41 5.518-5.518 5.518H39.13a1.302 1.302 0 1 1 0-2.605h4.356c1.67 0 2.914-1.243 2.914-2.913v-26.42c0-1.67-1.244-2.913-2.914-2.913H39.13c-.72 0-1.302-.583-1.302-1.302M13.851 33.674c-1.67 0-2.914 1.244-2.914 2.914v26.56c0 1.67 1.244 2.913 2.914 2.913h4.357a1.302 1.302 0 1 1 0 2.604H13.85c-3.108 0-5.518-2.41-5.518-5.518v-26.56c0-3.108 2.41-5.517 5.518-5.517h4.357a1.302 1.302 0 0 1 0 2.604z"
            clipRule="evenodd"
          ></path>
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M83.4 50.322c.718 0 1.301.583 1.301 1.302v4.357a1.302 1.302 0 0 1-2.604 0v-4.357c0-.719.583-1.302 1.302-1.302M77.355 50.322c.72 0 1.302.583 1.302 1.302v8.713a1.302 1.302 0 1 1-2.604 0v-8.713c0-.719.583-1.302 1.302-1.302M71.171 50.322c.72 0 1.302.583 1.302 1.302v18.55a1.302 1.302 0 1 1-2.604 0v-18.55c0-.719.583-1.302 1.302-1.302M64.99 50.322c.72 0 1.302.583 1.302 1.302v12.929a1.302 1.302 0 0 1-2.604 0V51.624c0-.719.583-1.302 1.302-1.302M58.946 50.322c.72 0 1.302.583 1.302 1.302v4.357a1.302 1.302 0 0 1-2.604 0v-4.357c0-.719.583-1.302 1.302-1.302"
            clipRule="evenodd"
          ></path>
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M83.396 45.964c.72 0 1.302.583 1.302 1.302l.003 4.358a1.303 1.303 0 0 1-2.604 0l-.003-4.358c0-.72.583-1.302 1.302-1.302M77.355 41.748c.72 0 1.302.583 1.302 1.302v8.574a1.3 1.3 0 0 1-1.302 1.3 1.3 1.3 0 0 1-1.302-1.3V43.05c0-.719.583-1.302 1.302-1.302M71.17 31.911c.72 0 1.302.583 1.302 1.302l.001 18.411a1.301 1.301 0 0 1-2.604 0v-18.41c0-.72.582-1.303 1.301-1.303M64.987 37.531c.719 0 1.302.583 1.302 1.302v12.789a1.302 1.302 0 0 1-2.604 0V38.832c0-.719.583-1.302 1.302-1.302M58.946 45.964c.719 0 1.302.583 1.302 1.303v4.357a1.301 1.301 0 0 1-2.604 0v-4.358c0-.719.582-1.302 1.302-1.302M52.902 50.32c.72 0 1.302.583 1.302 1.302v1.546a1.302 1.302 0 1 1-2.604 0v-1.546c0-.719.583-1.302 1.302-1.302"
            clipRule="evenodd"
          ></path>
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M52.902 48.774c.72 0 1.302.583 1.302 1.303v1.545a1.302 1.302 0 1 1-2.604 0v-1.545c0-.72.583-1.303 1.302-1.303M27.96 35.777c1.131 0 2.207.677 2.685 1.873l.013.034 7.448 20.236a1.302 1.302 0 1 1-2.444.9l-7.44-20.215a.4.4 0 0 0-.136-.184.22.22 0 0 0-.251 0 .4.4 0 0 0-.136.184l-7.44 20.214a1.302 1.302 0 1 1-2.444-.9l7.46-20.27c.479-1.195 1.554-1.872 2.685-1.872"
            clipRule="evenodd"
          ></path>
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M20.968 51.766c0-.719.583-1.302 1.302-1.302h24.593a1.302 1.302 0 1 1 0 2.605H22.27c-.72 0-1.302-.584-1.302-1.303"
            clipRule="evenodd"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4354_7352">
          <path fill="#fff" d="M0 0h100v100H0z"></path>
        </clipPath>
        <clipPath id="clip1_4354_7352">
          <path fill="#fff" d="M0 0h100v100H0z"></path>
        </clipPath>
        <filter
          id="filter0_f_4354_7352"
          width="91.667"
          height="91.667"
          x="-4.167"
          y="-4.167"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_4354_7352"
            stdDeviation="12.5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter1_f_4354_7352"
          width="91.667"
          height="91.667"
          x="12.5"
          y="12.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_4354_7352"
            stdDeviation="12.5"
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id="paint0_linear_4354_7352"
          x1="37.5"
          x2="79.167"
          y1="58.333"
          y2="58.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5E3B"></stop>
          <stop offset="1" stopColor="#C516E1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MurfStudioIllustration;
