import { ReactNode } from "react";
import CloseRounded from "@mui/icons-material/CloseRounded";
import { Box, DialogTitle, IconButton, Typography } from "@mui/material";

const DialogHeader = ({
  title,
  icon,
  onClose
}: {
  title: string;
  icon?: ReactNode;
  onClose?: () => void;
}) => {
  return (
    <DialogTitle
      sx={{
        paddingX: 3,
        paddingY: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}
    >
      <Box display="flex" gap={2} alignItems="center">
        {icon}
        <Typography variant="h6">{title}</Typography>
      </Box>
      {onClose ? (
        <IconButton size="small" onClick={onClose}>
          <CloseRounded />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default DialogHeader;
