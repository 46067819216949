import { LANGUAGES } from "@/constants/languages";
import { api } from "@/lib/axios";

export interface CreateProjectParams {
  projectName: string;
}
interface GetProjectParams {
  projectId: string;
}

export interface DuplicateProjectParams extends GetProjectParams {
  projectName: string;
}
export interface UpdateProjectParams {
  projectId: string | undefined;
  payload: {
    projectName?: string;
    qualityCheckEnabled?: boolean;
    lipSyncEnabled?: boolean;
    currentStep?: number;
    projectId?: string;
    targetLocales?: LANGUAGES[] | null;
    sourceLocale?: LANGUAGES | null;
    createdBy?: string;
    createdAt?: Date;
    dueBy?: Date;
    draft?: boolean;
    draftState?:
      | "SETUP_PROJECT"
      | "UPLOAD_FILES"
      | "SELECT_LANGUAGE"
      | "REQUIREMENTS"
      | "FINISH"
      | "DONE";
    archived?: boolean;
    projectType?: "AUTO_DETECT";
  };
}

export interface AddLanguagesParams {
  projectId: string;
  dubPreviousFiles: boolean;
  languages: string[];
}

export interface ApproveAllDubsOfProject {
  projectId: string;
  sourceFileIdToDubsMap: {
    [sourceFileId: string]: string[];
  };
}

export const clientProjectApi = {
  create: (payload: CreateProjectParams) =>
    api.post(`factory/create/project`, payload),
  update: ({ projectId, payload }: UpdateProjectParams) => {
    if (!Object.prototype.hasOwnProperty.call(payload, "archived")) {
      payload.archived = false;
    }
    return api.put(`project/${projectId}/save-project`, payload);
  },
  getById: ({ projectId }: GetProjectParams) => api.get(`project/${projectId}`),
  addLanguages: ({
    projectId,
    dubPreviousFiles,
    languages
  }: AddLanguagesParams) =>
    api.put(
      `project/${projectId}/add-languages/${dubPreviousFiles}`,
      languages
    ),
  duplicateProject: ({ projectId, projectName }: DuplicateProjectParams) =>
    api.post(`project/${projectId}/duplicate`, projectName, {
      headers: {
        "Content-Type": "text/plain"
      }
    }),
  approveAllDubsOfProject: ({
    projectId,
    sourceFileIdToDubsMap
  }: ApproveAllDubsOfProject) =>
    api.post(`project/${projectId}/approve-dubs`, sourceFileIdToDubsMap)
};
