export enum MIXPANEL_EVENTS {
  LOGIN_START = "Login Start",
  LOGIN_SUCCESS = "Login Success",
  SIGNUP_START = "Signup Start",
  SIGNUP_SUCCESS = "Signup Success",
  ONE_TAP_SUCCESS = "One Tap Success",
  ONE_TAP_DISMISSED = "One Tap Dismissed",
  VERIFICATION_LINK = "Verification Link",
  BOOK_DUB_DEMO = "Book Dub Demo",
  CREATE_PROJECT_START = "Create Project Start",
  CREATE_PROJECT_SUCCESS = "Create Project Success",
  FILE_UPLOAD_START = "File Upload Start",
  FILE_UPLOAD_SUCCESS = "File Upload Success",
  DUB_LANGUAGE_SELECT = "Dub Language Select",
  DUB_REQUIREMENT_SELECT = "Dub Requirement Select",
  DUB_START = "Dub Start",
  OPEN_PROJECT = "Open Project",
  OPEN_DUB = "Open Dub",
  DOWNLOAD = "Download",
  PAGE_VIEW = "Page View",
  RENAME_START = "Rename Start",
  RENAME_SUCCESS = "Rename Success",
  ARCHIVE_SUCCESS = "Archive Success",
  UNARCHIVE_SUCCESS = "Unarchive Success",
  COPY_DUB = "Copy Dub",
  STATUS_FILTER = "Status Filter",
  LANGUAGE_FILTER = "Language Filter",
  OTHER_FILTERS = "Other Filters",
  INVITE_USER_START = "Invite User Start",
  INVITE_USER_SUCCESS = "Invite User Success",
  REMOVE_USER_START = "Remove User Start",
  REMOVE_USER_SUCCESS = "Remove User Success",
  THEME_SELECT = "Theme Select",
  DUB_PITCH_CHANGE = "Dub Pitch Change",
  RESET_CHANGES_START = "Reset Changes Start",
  RESET_CHANGES_SUCCESS = "Reset Changes Success",
  VOICE_VARIANT_START = "Voice Variant Start",
  VOICE_VARIANT_SUCCESS = "Voice Variant Success",
  PAUSE_START = "Pause Start",
  PAUSE_SUCCESS = "Pause Success",
  MARK_AS_DONE = "Mark As Done",
  PLAY_DUB = "Play Dub",
  EDIT_TRANSLATION_START = "Edit Translation Start",
  EDIT_TRANSLATION_SUCCESS = "Edit Translation Success",
  TRANSLATE_START = "Translate Start",
  TRANSLATE_SUCCESS = "Translate Success",
  PRONUNCIATION_START = "Pronunciation Start",
  PRONUNCIATION_PREVIEW = "Pronunciation Preview",
  PRONUNCIATION_SUCCESS = "Pronunciation Success",
  CUSTOM_PRONUNCIATION_START = "Custom Pronunciation Start",
  CUSTOM_PRONUNCIATION_PREVIEW = "Custom Pronunciation Preview",
  CUSTOM_PRONUNCIATION_SUCCESS = "Custom Pronunciation Success",
  PRONUNCIATION_GIF_OPENED = "Pronunciation Gif Opened",
  VIDEO_PREVIEW_OPEN = "Video Preview Open",
  VIDEO_PREVIEW_CLOSE = "Video Preview Close",
  VIDEO_PREVIEW_DRAG = "Video Preview Drag",
  VIDEO_PREVIEW_RESIZE = "Video Preview Resize",
  RENDER_TIME = "Render Time",
  SPEAKER_CHANGE_START = "Speaker Change Start",
  SPEAKER_CHANGE_SUCCESS = "Speaker Change Success",
  PLAYED_INTRO_VIDEO = "Played Intro Video",
  // COMMENT RELATED EVENTS
  DUB_CHANGE_REQUEST_START = "Dub Change Request Start",
  ADD_ISSUE_START = "Add Issue Start",
  ADD_ISSUE_SUCCESS = "Add Issue Success",
  ADD_ISSUE_DISCARDED = "Add Issue Discarded",
  COMMENT_START = "Comment Start",
  COMMENT_SUCCESS = "Comment Success",
  SHARE_DUB_START = "Share Dub Start",
  SHARE_DUB_SUCCESS = "Share Dub Success",
  EXPORT_DROPDOWN_OPENED = "Export Dropdown Opened",
  EXPORT_START = "Export Start",
  EXPORT_SUCCESS = "Export Success",
  EXPORT_CANCELLED = "Export Cancelled",
  GA4_DATA_NOT_FOUND = "GA4 data not found",
  GA4_NOT_INITIALIZED = "GA4 not initialized",
  // Pricing Dialog Events
  UPGRADE_START_DISMISSED = "Upgrade Start Dismissed",
  PAYMENT_SUCCESS = "Payment Successful",
  PAYMENT_FAILED = "Payment Failed",
  UPGRADE_SETUP_SUCCESS = "Upgrade Setup Success",
  UPGRADE_START_SUMMARY = "Upgrade Start Summary",
  TOP_UP_AMOUNT_MODIFIED = "Top-up Amount Modified",
  AUTO_PAY_TOGGLED = "Auto-pay Toggled",
  AUTO_PAY_BALANCE_THRESHOLD_MODIFIED = "Auto-pay Balance Threshold Modified",
  AUTO_PAY_RECHARGE_AMOUNT_MODIFIED = "Auto-pay Recharge Amount Modified",
  MODIFY_AUTO_PAY_START = "Modify Auto-Pay Start",
  MODIFY_AUTO_PAY_SUCCESS = "Modify Auto-Pay Success",
  UPGRADE_START = "Upgrade Start",
  CONTACT_SALES = "Contact Sales",
  UP_SELL_CLICKED = "Up-sell Clicked",
  // MurfSuite
  MURF_SUITE_OPENED = "Murf Suite Opened",
  MURF_SUITE_SELECTED = "Murf Suite Selected",
  MURF_SUITE_DISMISSED = "Murf Suite Dismissed"
}
