import { Box, Button, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { IProductCard } from "../types";
import { BASE_URL, iconAsset } from "@/utils/links";
import useThemeProvider from "@/hooks/useThemeProvider";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { ArrowForwardRounded } from "@mui/icons-material";
import { newWindowTimeStamp } from "../constants";

const MURF_STUDIO_URL = BASE_URL;

const Image = styled("img")({});

const ProductCard = ({
  title,
  titleLogo,
  desc,
  image,
  illustration,
  id,
  invertLogo,
  url,
  isNew,
  section,
  ctaText,
  addedOn
}: IProductCard & { section: string }): React.ReactNode => {
  const { activeTheme } = useThemeProvider();

  const redirectUrl = url.startsWith("/") ? MURF_STUDIO_URL + url : url;

  const handleEvent = () => {
    trackMixpanelEvent(MIXPANEL_EVENTS.MURF_SUITE_SELECTED, {
      "Product Category": section,
      Product: title
    });
  };

  return (
    <Stack
      sx={{
        backgroundColor: (theme) => theme.palette.background.elevation3,
        borderRadius: 5,
        padding: 3,
        border: "1px solid",
        borderColor: "transparent",
        cursor: "pointer",
        "&:hover": {
          borderColor: "divider"
        }
      }}
      gap={1}
      direction={"row"}
      justifyContent={"space-between"}
      onClick={() => {
        window.open(redirectUrl, "_blank");
        handleEvent();
      }}
    >
      <Stack gap={3}>
        <Stack flexGrow={1} gap={1}>
          <Stack
            justifyContent={"start"}
            direction={"row"}
            alignItems={"center"}
            gap={1.5}
          >
            {titleLogo ? (
              <Image
                src={titleLogo[activeTheme]}
                sx={{ height: 32, maxWidth: 180, width: "fit-content" }}
              />
            ) : (
              <Typography variant="h5">{title}</Typography>
            )}
            {isNew && addedOn && addedOn + newWindowTimeStamp > Date.now() ? (
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: 1,
                  p: "2px 4px 1px 4px",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "12px",
                  width: "fit-content",
                  color: "common.white"
                }}
              >
                New
              </Box>
            ) : null}
          </Stack>

          <Typography
            variant="body2"
            color={"text.secondary"}
            maxWidth={"42ch"}
          >
            {desc}
          </Typography>
        </Stack>
        <Box>
          <Button
            variant="text"
            color="secondary"
            size="medium"
            endIcon={<ArrowForwardRounded />}
            href={redirectUrl}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
              handleEvent();
            }}
          >
            {ctaText ? ctaText : "Try now"}
          </Button>
        </Box>
      </Stack>
      <Stack alignItems={"center"} justifyContent={"center"}>
        <Box p={1.5}>
          {image ? (
            <Image
              src={iconAsset(image)}
              alt={id}
              sx={{
                height: 64,
                width: 64,
                p: 0.5,
                ...(invertLogo
                  ? {
                      filter: (theme) =>
                        theme.palette.mode === "light" ? "invert(1)" : "none"
                    }
                  : {})
              }}
            />
          ) : illustration ? (
            <Stack
              sx={{
                color: (theme) => theme.palette.secondary.main,
                height: 72,
                width: 72,
                p: 0.5
              }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {illustration}
            </Stack>
          ) : null}
        </Box>
      </Stack>
    </Stack>
  );
};

export default ProductCard;
