import MurfSuiteIcon from "@/components/assets/murfSuite/MurfSuiteIcon";
import { useAppDispatch } from "@/config/configureAppStore";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { setMurfSuiteOpenState } from "@/reducers/slices/dialogSlice/dialogSlice";
import { commonVariables } from "@/themes/themeVariables";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import { Box, IconButton } from "@mui/material";
import React from "react";
import { newWindowTimeStamp, productsInfo } from "../constants";

const MurfSuiteButton = (): React.ReactNode => {
  const dispatch = useAppDispatch();
  const openMurfSuite = () => {
    dispatch(setMurfSuiteOpenState(true));
    trackMixpanelEvent(MIXPANEL_EVENTS.MURF_SUITE_OPENED, {});
  };

  const isAnythingNew = productsInfo
    .flatMap((section) => ("products" in section ? section.products : []))
    .filter(
      (p) =>
        p && p.addedOn && p.isNew && p.addedOn + newWindowTimeStamp > Date.now()
    )?.length;

  return (
    <IconButton color="secondary" sx={{ p: 1 }} onClick={openMurfSuite}>
      <MurfSuiteIcon />
      {isAnythingNew ? (
        <Box
          sx={{
            backgroundImage: commonVariables.primaryGradient,
            height: (theme) => theme.spacing(1),
            width: (theme) => theme.spacing(1),
            borderRadius: "50%",
            position: "absolute",
            top: 0,
            right: 0
          }}
        ></Box>
      ) : null}
    </IconButton>
  );
};

export default MurfSuiteButton;
