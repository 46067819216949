export const BASE_URL = "https://murf.ai";
export const PUBLIC_ASSETS_URL = `${BASE_URL}/public-assets`;
export const MURF_DUB_ASSET_URL = `${PUBLIC_ASSETS_URL}/murf_dub`;

export function getIconUrl({
  iconNameWithExtension
}: {
  iconNameWithExtension: string;
}): string {
  return `${MURF_DUB_ASSET_URL}/icons/${iconNameWithExtension}`;
}

export const murfLogoUrl =
  PUBLIC_ASSETS_URL + "/v2-assets/logos/murf-logo-rebranded.svg";
export const murfLogoWithNameUrl = `${PUBLIC_ASSETS_URL}/v2-assets/logos/murf-rebranded-dub-light.svg`;
export const murfLogoWithNameDarkUrl = `${PUBLIC_ASSETS_URL}/v2-assets/logos/murf-rebranded-dub-dark.svg`;

export function getImageUrl({
  imageNameWithExtension
}: {
  imageNameWithExtension: string;
}): string {
  return `${MURF_DUB_ASSET_URL}/images/${imageNameWithExtension}`;
}

export function getIllustrationUrl({
  illustrationNameWithExtension
}: {
  illustrationNameWithExtension: string;
}): string {
  return `${MURF_DUB_ASSET_URL}/illustrations/${illustrationNameWithExtension}`;
}

export function getLanguageFlag({ language }: { language: string }): string {
  return `${PUBLIC_ASSETS_URL}/countries/${language}`;
}

export function getDemoResourcesUrl(assetWithExtension: string) {
  return `${MURF_DUB_ASSET_URL}/demo-resources/${assetWithExtension}`;
}

export const publicAsset = (asset: string) => `${PUBLIC_ASSETS_URL}/${asset}`;

export const logoAsset = (path: string) =>
  publicAsset(`v2-assets/logos/${path}`);

export const iconAsset = (path: string) =>
  publicAsset(`v2-assets/icons/${path}`);

export const asset = (media: string) => publicAsset(`new-home/${media}`);

export const paymentCardImage = (path: string) =>
  publicAsset(`payment-card-images/${path}`);
