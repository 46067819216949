import React from "react";

const MurfApiIllustration = (): React.ReactNode => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
      viewBox="0 0 100 100"
    >
      <g filter="url(#filter0_f_4405_37140)" opacity="0.6">
        <path
          fill="#A000BA"
          d="M62.5 41.667c0 11.505-9.327 20.833-20.833 20.833s-20.834-9.327-20.834-20.833 9.328-20.834 20.834-20.834S62.5 30.161 62.5 41.667"
        ></path>
      </g>
      <g filter="url(#filter1_f_4405_37140)" opacity="0.6">
        <path
          fill="url(#paint0_linear_4405_37140)"
          d="M79.167 58.333c0 11.506-9.328 20.834-20.834 20.834S37.5 69.839 37.5 58.333 46.827 37.5 58.333 37.5s20.834 9.327 20.834 20.833"
        ></path>
      </g>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.356 12.55a5.67 5.67 0 0 0-5.697 5.696v29.562a5.67 5.67 0 0 0 5.697 5.696h3.063a1.197 1.197 0 0 1 0 2.394h-3.063a8.06 8.06 0 0 1-8.09-8.09V18.246c0-4.49 3.6-8.09 8.09-8.09h62.8c4.491 0 8.09 3.6 8.09 8.09v29.562c0 4.49-3.599 8.09-8.09 8.09h-3.063a1.197 1.197 0 0 1 0-2.394h3.064a5.67 5.67 0 0 0 5.696-5.696V18.246a5.67 5.67 0 0 0-5.696-5.696z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M49.752 55.897a8.76 8.76 0 1 0 0 17.519 8.76 8.76 0 0 0 0-17.52m-11.153 8.76c0-6.16 4.993-11.154 11.153-11.154s11.153 4.994 11.153 11.153S55.91 75.81 49.752 75.81s-11.153-4.993-11.153-11.153"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M44.128 40.874a1.2 1.2 0 0 1 1.183-1.015h9.343c.591 0 1.093.43 1.183 1.015l.814 5.29c2.092.841 4.103 1.935 5.914 3.473l4.992-1.889a1.2 1.2 0 0 1 1.46.521l4.595 7.965c.295.511.174 1.16-.285 1.53l-4.18 3.371c.223 1.172.337 2.284.337 3.51s-.114 2.337-.337 3.509l4.18 3.37c.459.37.58 1.02.285 1.53l-4.595 7.965a1.2 1.2 0 0 1-1.46.521l-4.963-1.877a17 17 0 0 1-5.95 3.511l-.807 5.241a1.2 1.2 0 0 1-1.183 1.015h-9.343c-.59 0-1.093-.431-1.183-1.015l-.814-5.29c-2.092-.842-4.103-1.936-5.914-3.473l-4.992 1.888a1.2 1.2 0 0 1-1.46-.52l-4.595-7.966a1.2 1.2 0 0 1 .285-1.53l4.18-3.37a18.3 18.3 0 0 1-.336-3.51c0-1.19.107-2.391.327-3.517l-4.17-3.363a1.2 1.2 0 0 1-.286-1.53l4.595-7.965a1.2 1.2 0 0 1 1.46-.52l4.963 1.877a17 17 0 0 1 5.95-3.512zm2.21 1.378-.764 4.96c-.07.458-.4.834-.845.965a14.6 14.6 0 0 0-6.23 3.681c-.334.333-.83.44-1.27.273l-4.716-1.784-3.575 6.197 3.951 3.186c.363.293.523.77.41 1.222-.28 1.117-.424 2.397-.424 3.692 0 1.28.14 2.417.431 3.723.099.444-.063.906-.417 1.191l-3.951 3.187 3.575 6.197 4.716-1.784c.424-.16.902-.068 1.235.24 1.845 1.703 3.994 2.858 6.348 3.74.405.153.697.512.763.94l.762 4.958h7.29l.764-4.959c.07-.458.4-.835.845-.966a14.6 14.6 0 0 0 6.23-3.68c.334-.333.83-.44 1.27-.273l4.717 1.784 3.575-6.197-3.952-3.187a1.2 1.2 0 0 1-.417-1.19c.29-1.307.431-2.444.431-3.724 0-1.279-.14-2.417-.43-3.723a1.2 1.2 0 0 1 .416-1.19l3.952-3.187-3.576-6.197-4.715 1.784c-.424.16-.903.068-1.236-.24-1.845-1.703-3.994-2.858-6.348-3.741a1.2 1.2 0 0 1-.762-.939l-.763-4.959zM28.62 19.196c.981 0 2.064.536 2.499 1.696l.001.004 7.046 18.993a1.197 1.197 0 1 1-2.244.833l-7.044-18.99a.18.18 0 0 0-.076-.096.35.35 0 0 0-.183-.046.35.35 0 0 0-.182.046.18.18 0 0 0-.075.095l-7.045 18.99a1.197 1.197 0 0 1-2.244-.832l7.046-18.993.002-.004c.434-1.16 1.517-1.696 2.499-1.696"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22.675 32.03c0-.66.535-1.197 1.196-1.197h22.21a1.197 1.197 0 0 1 0 2.394h-22.21c-.66 0-1.197-.536-1.197-1.197M75.332 26.547c.66 0 1.196.536 1.196 1.197v8.731a1.197 1.197 0 0 1-2.393 0v-8.73c0-.662.536-1.198 1.197-1.198M69.366 20.421c.66 0 1.196.536 1.196 1.197v20.985a1.197 1.197 0 0 1-2.393 0V21.618c0-.661.536-1.197 1.197-1.197M63.54 23.638c.661 0 1.197.536 1.197 1.197v14.551a1.197 1.197 0 0 1-2.393 0V24.835c0-.661.536-1.197 1.197-1.197M57.564 26.547c.66 0 1.197.536 1.197 1.197v8.731a1.197 1.197 0 0 1-2.394 0v-8.73c0-.662.536-1.198 1.197-1.198M51.901 28.988c.661 0 1.197.536 1.197 1.197v3.83a1.197 1.197 0 1 1-2.393 0v-3.83c0-.66.535-1.197 1.196-1.197M80.548 28.988c.66 0 1.196.536 1.196 1.197v3.83a1.197 1.197 0 1 1-2.393 0v-3.83c0-.66.536-1.197 1.197-1.197"
        clipRule="evenodd"
      ></path>
      <defs>
        <filter
          id="filter0_f_4405_37140"
          width="91.667"
          height="91.667"
          x="-4.167"
          y="-4.167"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_4405_37140"
            stdDeviation="12.5"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter1_f_4405_37140"
          width="91.667"
          height="91.667"
          x="12.5"
          y="12.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_4405_37140"
            stdDeviation="12.5"
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id="paint0_linear_4405_37140"
          x1="37.5"
          x2="79.167"
          y1="58.333"
          y2="58.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5E3B"></stop>
          <stop offset="1" stopColor="#C516E1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MurfApiIllustration;
