import { useTypedSelector } from "@/config/configureAppStore";
import { PaletteMode, useMediaQuery } from "@mui/material";
import { useMemo } from "react";

const useThemeProvider = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const appTheme = useTypedSelector((state) => state.globalState.appTheme);

  const activeTheme = useMemo<PaletteMode>(() => {
    return (appTheme === "system" && prefersDarkMode) || appTheme === "dark"
      ? "dark"
      : "light";
  }, [appTheme, prefersDarkMode]);

  return {
    activeTheme
  };
};

export default useThemeProvider;
