import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "@/constants/mixpanel";
import { trackMixpanelEvent } from "@/utils/mixpanel";
import ReactGA from "react-ga4";

enum OPR {
  STARTS_WITH = "startsWith",
  ENDS_WITH = "endsWith",
  EQUALS = "equals"
}

const PAGE_TYPES: { [key: string]: { type: string; operation: OPR } } = {
  "/auth/login": {
    type: "Login",
    operation: OPR.STARTS_WITH
  },
  "/auth/signup": {
    type: "Signup",
    operation: OPR.STARTS_WITH
  },
  "/auth/sso": {
    type: "SSO",
    operation: OPR.STARTS_WITH
  },
  "/auth/verify-email": {
    type: "Email Verification",
    operation: OPR.STARTS_WITH
  },
  "/auth/book-demo": {
    type: "Book Demo",
    operation: OPR.STARTS_WITH
  },
  "/auth/email-verified": {
    type: "Email Verified",
    operation: OPR.STARTS_WITH
  },
  "/auth/reset-password": {
    type: "Reset password",
    operation: OPR.STARTS_WITH
  },
  "/auth/logout": {
    type: "Logout",
    operation: OPR.STARTS_WITH
  },
  "/home": {
    type: "Home",
    operation: OPR.STARTS_WITH
  },
  "/profile/workspace/credit-utilization": {
    type: "Credit Utilization",
    operation: OPR.EQUALS
  },
  "/profile/user": {
    type: "Profile Settings",
    operation: OPR.EQUALS
  },
  "/profile/workspace/manage": {
    type: "User Management",
    operation: OPR.EQUALS
  },
  "/profile/workspace": {
    type: "Workspace",
    operation: OPR.EQUALS
  }
};

const usePageView = () => {
  const location = useLocation();

  const getPageType = useCallback((pathname: string) => {
    let pageType: string | undefined;
    Object.keys(PAGE_TYPES).some((key: string) => {
      if (PAGE_TYPES[key].operation === OPR.EQUALS) {
        if (pathname === key) {
          pageType = PAGE_TYPES[key].type;
        }
      } else if (PAGE_TYPES[key].operation === OPR.STARTS_WITH) {
        if (pathname.startsWith(key)) {
          pageType = PAGE_TYPES[key].type;
        }
      }
      return pathname.startsWith(key);
    });

    if (!pageType) {
      if (pathname.startsWith("/project")) {
        if (pathname.startsWith("/project/create")) {
          pageType = "Create Project";
        } else if (pathname.endsWith("/preview")) {
          pageType = "Project preview";
        } else {
          pageType = "Project Details";
        }
      }
    }
    return pageType;
  }, []);

  const trackPageView = useCallback(
    (page: string) => {
      if (page.startsWith("/internal")) return;
      if (mixpanel.__loaded) {
        const pageType = getPageType(page);
        if (pageType) {
          trackMixpanelEvent(MIXPANEL_EVENTS.PAGE_VIEW, {
            "Page Type": pageType
          });
          ReactGA.send({ hitType: "pageview", page: pageType });
        }
      } else {
        setTimeout(() => trackPageView(page), 1000);
      }
    },
    [getPageType]
  );

  useEffect(() => {
    trackPageView(location?.pathname);
  }, [location?.pathname, trackPageView]);

  return {};
};

export default usePageView;
