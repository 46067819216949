import React from "react";

const VoiceCloningIllustration = (): React.ReactNode => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 80 80"
    >
      <g clipPath="url(#clip0_4405_36241)">
        <g filter="url(#filter0_f_4405_36241)" opacity="0.6">
          <path
            fill="#A000BA"
            d="M50 33.333C50 42.538 42.538 50 33.333 50c-9.204 0-16.666-7.462-16.666-16.667 0-9.204 7.462-16.666 16.666-16.666S50 24.129 50 33.333"
          ></path>
        </g>
        <g filter="url(#filter1_f_4405_36241)" opacity="0.6">
          <path
            fill="url(#paint0_linear_4405_36241)"
            d="M63.333 46.667c0 9.204-7.462 16.666-16.666 16.666S30 55.871 30 46.667 37.462 30 46.667 30c9.204 0 16.666 7.462 16.666 16.667"
          ></path>
        </g>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M24.784 43.348c-1.838 1.824-4.132 3.091-7.218 3.091a1.042 1.042 0 0 1 0-2.083c2.426 0 4.217-.965 5.75-2.487 1.575-1.562 2.837-3.67 4.182-5.929l.038-.063c1.301-2.186 2.687-4.512 4.466-6.278 1.838-1.824 4.132-3.091 7.218-3.091 3.19 0 5.724 1.244 7.986 3.009 1.667 1.301 3.252 2.942 4.852 4.6.525.542 1.05 1.087 1.582 1.623 4.353 4.385 9.481 8.616 18.65 8.616a1.042 1.042 0 0 1 0 2.083c-9.99 0-15.623-4.693-20.128-9.232a176 176 0 0 1-1.688-1.73c-1.57-1.626-3.008-3.114-4.55-4.317-2.028-1.582-4.12-2.569-6.704-2.569-2.426 0-4.217.964-5.75 2.486-1.574 1.563-2.837 3.671-4.182 5.93l-.038.063c-1.301 2.185-2.687 4.511-4.466 6.278"
          clipRule="evenodd"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M37.917 35.952C32.95 41.068 26.682 46.44 17.567 46.44a1.042 1.042 0 0 1 0-2.083c8.206 0 13.946-4.8 18.855-9.855.795-.82 1.577-1.655 2.346-2.476 1.558-1.664 3.061-3.27 4.513-4.567 2.173-1.942 4.49-3.444 7.094-3.444 3.1 0 5.209 1.5 6.8 3.59 1.328 1.744 2.354 3.983 3.35 6.156q.237.518.473 1.028c1.183 2.546 2.407 4.936 4.144 6.7 1.693 1.718 3.898 2.868 7.149 2.868a1.042 1.042 0 0 1 0 2.083c-3.836 0-6.569-1.393-8.633-3.49-2.019-2.049-3.37-4.745-4.55-7.283l-.49-1.065c-1.008-2.193-1.925-4.19-3.101-5.736-1.313-1.724-2.862-2.767-5.142-2.767-1.793 0-3.61 1.04-5.706 2.913-1.384 1.237-2.776 2.725-4.29 4.342-.783.838-1.599 1.71-2.462 2.6"
          clipRule="evenodd"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M16.525 45.397c0-.575.466-1.041 1.041-1.041 9.8 0 16.625 3.562 22.374 7.04q1.09.663 2.124 1.303c2.05 1.267 3.955 2.444 5.878 3.39 2.54 1.252 5.011 2.047 7.682 2.047 2.87 0 4.733-.794 6.07-1.911 1.369-1.144 2.275-2.697 3.098-4.392q.27-.56.541-1.148c.609-1.31 1.245-2.679 2.04-3.754 1.103-1.491 2.61-2.575 4.918-2.575a1.042 1.042 0 0 1 0 2.083c-1.5 0-2.436.64-3.243 1.731-.658.89-1.168 1.984-1.755 3.245-.197.424-.403.867-.627 1.328-.85 1.75-1.913 3.64-3.636 5.08-1.755 1.467-4.108 2.396-7.406 2.396-3.104 0-5.899-.928-8.603-2.26-2.02-.995-4.046-2.247-6.12-3.529-.675-.416-1.354-.836-2.04-1.251-5.636-3.411-12.065-6.74-21.295-6.74a1.04 1.04 0 0 1-1.041-1.042"
          clipRule="evenodd"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M16.394 45.398c0-.576.467-1.042 1.042-1.042 8.748 0 14.185 4.407 18.72 8.611q.699.649 1.364 1.277c1.746 1.644 3.36 3.162 5.062 4.366 2.102 1.485 4.24 2.413 6.744 2.413 2.339 0 4.08-.9 5.61-2.325 1.454-1.355 2.673-3.138 3.998-5.078l.327-.478c2.836-4.141 6.199-8.786 13.03-8.786a1.042 1.042 0 0 1 0 2.083c-5.636 0-8.44 3.689-11.312 7.88l-.35.514c-1.292 1.892-2.64 3.868-4.273 5.39-1.826 1.7-4.054 2.883-7.03 2.883-3.074 0-5.627-1.156-7.946-2.795-1.834-1.296-3.585-2.945-5.342-4.6q-.648-.61-1.298-1.216c-4.454-4.129-9.385-8.055-17.304-8.055a1.04 1.04 0 0 1-1.042-1.042"
          clipRule="evenodd"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M16.525 45.397c0-.575.466-1.041 1.041-1.041 7.688 0 11.673 5.37 14.94 10.238l.681 1.02c1.402 2.106 2.694 4.048 4.169 5.553 1.672 1.707 3.479 2.743 5.8 2.743 1.774 0 3.356-.974 5.081-2.741 1.151-1.18 2.26-2.59 3.474-4.137.633-.807 1.296-1.651 2.01-2.519 4.073-4.955 9.426-10.157 18.57-10.157a1.042 1.042 0 0 1 0 2.083c-8.18 0-12.997 4.576-16.961 9.397a136 136 0 0 0-1.883 2.36c-1.263 1.607-2.492 3.171-3.72 4.428-1.854 1.9-3.947 3.37-6.57 3.37-3.059 0-5.37-1.409-7.29-3.369-1.624-1.657-3.04-3.787-4.43-5.878l-.66-.992c-3.295-4.908-6.725-9.316-13.21-9.316a1.04 1.04 0 0 1-1.042-1.042"
          clipRule="evenodd"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M23.475 18.88a4.84 4.84 0 0 0-4.864 4.865v41.863a4.84 4.84 0 0 0 4.864 4.864h42.782a4.84 4.84 0 0 0 4.864-4.864V23.745a4.84 4.84 0 0 0-4.864-4.864zm-6.947 4.865a6.923 6.923 0 0 1 6.947-6.947h42.782a6.923 6.923 0 0 1 6.947 6.947v41.863a6.923 6.923 0 0 1-6.947 6.947H23.475a6.923 6.923 0 0 1-6.947-6.947z"
          clipRule="evenodd"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M18.399 13.232a4.84 4.84 0 0 0-4.864 4.864V64.29a1.042 1.042 0 0 1-2.083 0V18.095a6.923 6.923 0 0 1 6.947-6.947h47.638a1.042 1.042 0 1 1 0 2.084z"
          clipRule="evenodd"
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M13.614 8.75a4.84 4.84 0 0 0-4.864 4.864v45.144a1.042 1.042 0 1 1-2.083 0V13.614a6.923 6.923 0 0 1 6.947-6.947h47.9a1.042 1.042 0 0 1 0 2.083z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_f_4405_36241"
          width="73.333"
          height="73.333"
          x="-3.333"
          y="-3.333"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_4405_36241"
            stdDeviation="10"
          ></feGaussianBlur>
        </filter>
        <filter
          id="filter1_f_4405_36241"
          width="73.333"
          height="73.333"
          x="10"
          y="10"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feGaussianBlur
            result="effect1_foregroundBlur_4405_36241"
            stdDeviation="10"
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id="paint0_linear_4405_36241"
          x1="30"
          x2="63.333"
          y1="46.667"
          y2="46.667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5E3B"></stop>
          <stop offset="1" stopColor="#C516E1"></stop>
        </linearGradient>
        <clipPath id="clip0_4405_36241">
          <path fill="#fff" d="M0 0h80v80H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default VoiceCloningIllustration;
